const batch = $('.batch');

const popOptions = {
    animation: true,
    placement: 'top',
    template: '<div class="popover" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
    trigger: 'hover',
    //trigger: 'click',
    html: true,
    container: 'body'
}

const dispositivo = () => {
    const device = navigator.userAgent.toLocaleLowerCase();
    (device.search(/iphone|ipod|ipad|android/) == -1) ? batch.popover(popOptions) : batch.popover(popOptions);
}



const moneda = (number, decimals, dec_point, thousands_sep) => {

    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');

    const n    = !isFinite(+number) ? 0 :+number;
    const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
    const sep  = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep;
    const dec  = (typeof dec_point === 'undefined') ? '.' : dec_point;
    let s    = '';


    const toFixedFix  = () =>{
        let k = Math.pow(10, prec);
        return ` ${(Math.round(n * k)/k).toFixed(prec)}`;
    }


    s = (prec ? toFixedFix(n, prec) : ` ${Math.round(n)}`).split('.');

    if( s[0].length > 3){
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if((s[1] || '').length < prec){
        s[1] = s[1] || '';
        s[1] += new Array(prec -s[1].length + 1).join('0');
    }

    return s.join(dec);

}



const preparaEnvio = () => {
    
    const form = $('#frmContactForm, #infoLote');
    const submit = $('#submitCotizacion');
    const alertx = $('.successMsgCotizador');


    form.on('submit', (e) => {
        e.preventDefault();

        $('#frmContactForm .error').remove();

        form.find('#selectEnganche option:disabled').attr('disabled',false);
        form.find('#selectEnganche').attr('disabled',false);

        let hasError = false;

        $('.required').each(function(){
            if($(this).val().trim() === ''){
                $(this).parent().append('<span class="error"><i class="fa fa-exclamation-triangle"></i></span>');
                hasError = true;
                grecaptcha.reset();
            } else if( $(this).hasClass('email') ){               
                const emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if(!emailReg.test($(this).val().trim())){
                    $(this).parent().append('<span class="error"><i class="fa fa-exclamation-triangle"></i></span>');
                    hasError = true;
                    grecaptcha.reset();
                }

            }
        });

        if(!hasError){
            submit.attr('disabled', true);
            $('#overlayc, #PleaseWaitc').show();

            const data = form.serialize();

            $.ajax({
                url: "Class/cotizador_lotes.php",
                type: "POST",
                data: data,
                dataType: "json",
                success: function({status, message, data}){

                    submit.attr('disabled', false);
                    $('#overlayc, #PleaseWaitc').hide();

                    if(status === 'success'){

                        $('#modalLote').modal('toggle');
                        alertx.html(message);
                        grecaptcha.reset();
                        
                    } else {
                        alertx.html(message);
                        grecaptcha.reset();
                    }

                },
                error: function(err){
                    console.log(err);
                    grecaptcha.reset();
                }
            });

        }

    });

}



const createRecaptcha = ()=> {
    grecaptcha.render(
        'recaptchaPopupCotizador',
        {
            sitekey: '6Lcsrg0dAAAAAMto3fny9zaHF7N6kf5qTpA7avEU',
            theme: 'light'
        }
    );
}


const calculaAll = (cuota, dbPrecioXm2, downpayment) => {
    
    const superficieLote = $('#inputSuperficie');
    const defsCuota      = [0, 12, 18, 24, 36];  
    let listOfPrice,downpay,finalencanche,totalfinal = 0;  
    const valCuota       = parseFloat(cuota.val());
    const valDbPriceXm2  = JSON.parse(dbPrecioXm2.val());

    const newArr = [];

    for(const precio in valDbPriceXm2){
        newArr.push(parseFloat(valDbPriceXm2[precio]));
    }



    if(valCuota === 0){
        listOfPrice = parseFloat(superficieLote.data('superficie')) * newArr[0];
        $('#price_lote').text(moneda(listOfPrice,2,'.',','));
        $('#pricem2_lote').text(newArr[0].toFixed(2));
        downpayment.attr('disabled',true);
        downpayment.val($("#selectEnganche option:first").val());
        $('#nameTotalPrice').html('Precio de contado');
        $("#engancheFinal").html(`$ ${moneda(listOfPrice,2,'.', ',')} MXN`);
        $("#finalEnganche").val(listOfPrice);
        $("#mensualidadFinal").html(0);
        $("#montoMensualFinal").html(`$ ${moneda(listOfPrice,2,'.',',')} MXN`);
    } else{

        defsCuota.forEach((v,k) =>{

            if(v === valCuota){
                listOfPrice = parseFloat(superficieLote.data('superficie')) * newArr[k];
                $('#pricem2_lote').text(newArr[k].toFixed(2));
                $('#price_lote').text(moneda(listOfPrice, 2, '.', ','));
            }

        });
        downpayment.attr('disabled',false);
        $('#nameTotalPrice').html('Monto mensual');
        downpay = parseFloat((downpayment.val() || 0));
        finalencanche = (downpay/100)* listOfPrice;
        totalfinal = ((listOfPrice - finalencanche) / valCuota);
        $("#engancheFinal").html(`$ ${moneda(finalencanche,2,'.', ',')} MXN`);
        $("#finalEnganche").val(finalencanche);
        $("#mensualidadFinal").html(valCuota);
        $("#montoMensualFinal").html(`$ ${moneda(totalfinal,2,'.',',')} MXN`);

    }

}


const imprimeValoresHtml = () => {

    setTimeout(() => {

        const downpayment    = $('#selectEnganche');
        const dbPrecioXm2    = $('#allPricesM2');
        const cuota          = $('#selectMensualidades');

        downpayment.append('<option value selected="selected" disabled="disabled">Escoge tu enganche</option><option value="17">%17 sin intereses</option>');
        cuota.append('<option value selected="selected" disabled="disabled">Escoge tu mensualidad</option><option value="0">Contado</option><option value="12">12 meses</option><option value="18">18 meses</option><option value="24">24 meses</option><option value="36">36 meses</option>');


        cuota.on('change', () => {
            calculaAll(cuota, dbPrecioXm2, downpayment);
        });

        downpayment.on('change', () => {
            calculaAll(cuota, dbPrecioXm2, downpayment);
        });

        createRecaptcha();
        preparaEnvio();

    }, 1000);
    
    

}


const initCotizador = () => {

    dispositivo();
    
    const modal   = $('#modalLote');
    const preLoad = $('#overlayc, #PleaseWaitc');
       

    modal.on('hidden.bs.modal', () => {
        preLoad.hide();
        modal.removeData('bs.modal');
    });


    modal.on('shown.bs.modal', ({ relatedTarget }) => {
        
        const refModal = modal.find('.modal-content');
        const buttonTarget = $(relatedTarget);

        refModal.load(buttonTarget.data('remote'));
        
        imprimeValoresHtml();

    });
    
}


initCotizador();
